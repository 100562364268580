import * as React from "react";
import { graphql } from "gatsby";

import Seo from "../components/Seo";
import Layout from "../components/Layout";
import Blog from "../components/Blog";
import * as styles from "./index.module.css";
import sophiaClimbing from "../images/sophiaClimbing.jpeg";
import sophiaBaking from "../images/sophiaBaking.jpg";
import sophiaCoffee from "../images/sophiaCoffee.jpg";

const About = ({ data, location }) => {
  const posts = data.allMarkdownRemark.nodes;
  return (
    <Layout location={location}>
      <Seo title="About Me" />
      <div className={styles.outerContainer}>
        <div className={styles.aboutContainer}>
          <section>
            <h1 className={styles.pageHeader}>About Me</h1>
            <div>
              <div className={styles.aboutImageContainer}>
                <img className={styles.aboutImage} src={sophiaClimbing} />
                <img className={styles.aboutImage} src={sophiaBaking} />
                <img className={styles.aboutImage} src={sophiaCoffee} />
              </div>
              <div>
                <p>
                  Hi, I’m Sophia! I’m a San Francisco native who enjoys
                  travelling and visiting new cities.
                </p>
                <p>
                  I usually try to visit new places for months at a time to
                  really try to get know the city. When I’m in a new city, I
                  love checking out the local restaurants, public
                  transportation, and indoor climbing gyms. I’m also always on
                  the lookout for new bakeries, especially ones with any fusion
                  or croissant based pastries.
                </p>

                <p>
                  My motivation for writing this blog is to document my travels
                  and share some of the things I’ve learned along the way.
                  Thanks for stopping by, I hope you enjoy reading about my
                  travels or learn something useful!
                </p>
              </div>
            </div>
          </section>

          <section>
            <h2>Connect</h2>
            <p>I also post on other websites.</p>
            <ul>
              <li>
                <a
                  href="https://sophiali.dev/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  sophiali.dev
                </a>
                <span>: My blog where I write about software engineering.</span>
              </li>

              <li>
                <a
                  href="https://sophisnacks.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  sophisnacks.com
                </a>
                <span>
                  : A website I built with some of my favorite restaurants
                  categorized by city.
                </span>
              </li>

              <li>
                <a
                  href="https://twitter.com/sophia_wyl"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Twitter
                </a>
                <span>: I occasionally tweet, mostly about bread.</span>
              </li>
            </ul>
          </section>

          <section>
            <h2>New to the blog?</h2>
            <p>Check out some recent posts.</p>
            <Blog posts={posts} />
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default About;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 3
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          categories
          path
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
